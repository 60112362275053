import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import HistoryIcon from '@mui/icons-material/History'
import HomeIcon from '@mui/icons-material/Home'
import ListAltIcon from '@mui/icons-material/ListAlt'
import LogoutIcon from '@mui/icons-material/Logout'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import ViewListIcon from '@mui/icons-material/ViewList'
import router from 'next/router'
import { setCookie } from 'nookies'

import { User } from '@/generated/api/@types'
import { pagesPath } from '@/generated/lib/$path'
import { EUserPermission } from '@/src/const/userPermission'

export type MenuItemType = {
  icon?: React.ReactNode
  label: string
  href?: string
  isOpen?: boolean
  show: ((loginUser: User) => boolean) | boolean
  subMenu?: Array<SubMenuItemType>
  onClick?: (e: any) => void
}

export type SubMenuItemType = {
  label: string
  href: string
  show: ((loginUser: User) => boolean) | boolean
}

export const navMenuItems: Array<MenuItemType> = [
  {
    label: 'Home',
    icon: <HomeIcon />,
    href: '/',
    show: true,
  },
  {
    label: '保留中一覧',
    icon: <ListAltIcon />,
    href: pagesPath.my.tasks.pending_list.$url().pathname,
    show: (loginUser) => loginUser.user_role.edit_tasks === EUserPermission.READ_WRITE,
  },
  {
    label: '業務一覧',
    icon: <HistoryIcon />,
    href: pagesPath.my.tasks.$url().pathname,
    show: (loginUser) => loginUser.user_role.edit_tasks !== EUserPermission.NONE,
  },
  {
    label: '葬儀一覧',
    icon: <FormatListBulletedIcon />,
    href: pagesPath.my.funerals.$url().pathname,
    show: (loginUser) => loginUser.user_role.edit_funerals !== EUserPermission.NONE,
  },
  {
    label: '引き継ぎ一覧',
    icon: <ViewListIcon />,
    href: pagesPath.call_center.handovers.$url().pathname,
    show: (loginUser) => loginUser.user_role.edit_handovers === EUserPermission.READ_WRITE,
  },
  {
    label: 'マスター管理',
    icon: <SupervisorAccountIcon />,
    isOpen: true,
    subMenu: [
      {
        label: '契約社',
        href: pagesPath.master.partners.$url().pathname,
        show: (loginUser) => loginUser.user_role.edit_masters === EUserPermission.READ_WRITE,
      },
      {
        label: '回線マスタ',
        href: pagesPath.master.phone_lines.$url().pathname,
        show: (loginUser) => loginUser.user_role.edit_masters === EUserPermission.READ_WRITE,
      },
      {
        label: '葬祭センター',
        href: pagesPath.master.centers.$url().pathname,
        show: (loginUser) => loginUser.user_role.edit_masters === EUserPermission.READ_WRITE,
      },
      {
        label: '店舗',
        href: pagesPath.master.stores.$url().pathname,
        show: (loginUser) => loginUser.user_role.edit_masters === EUserPermission.READ_WRITE,
      },
      {
        label: 'ホール',
        href: pagesPath.master.halls.$url().pathname,
        show: (loginUser) => loginUser.user_role.edit_masters === EUserPermission.READ_WRITE,
      },
      {
        label: 'JA',
        href: pagesPath.master.jas.$url().pathname,
        show: (loginUser) => loginUser.user_role.edit_masters === EUserPermission.READ_WRITE,
      },
      {
        label: 'JA支店',
        href: pagesPath.master.ja_branches.$url().pathname,
        show: (loginUser) => loginUser.user_role.edit_masters === EUserPermission.READ_WRITE,
      },
      {
        label: '地区',
        href: pagesPath.master.regions.$url().pathname,
        show: (loginUser) => loginUser.user_role.edit_masters === EUserPermission.READ_WRITE,
      },
      {
        label: 'エリア',
        href: pagesPath.master.areas.$url().pathname,
        show: (loginUser) => loginUser.user_role.edit_masters === EUserPermission.READ_WRITE,
      },
      {
        label: 'スタッフ',
        href: pagesPath.master.users.$url().pathname,
        show: (loginUser) =>
          loginUser.user_role.edit_admin_users === EUserPermission.READ_WRITE ||
          loginUser.user_role.edit_phone_line_users !== EUserPermission.NONE,
      },
      {
        label: '件数一覧',
        href: pagesPath.master.summary.$url().pathname,
        show: (loginUser) => loginUser.user_role.edit_masters === EUserPermission.READ_WRITE,
      },
    ],
    show: (loginUser) =>
      loginUser.user_role.edit_masters !== EUserPermission.NONE ||
      loginUser.user_role.edit_phone_line_users !== EUserPermission.NONE,
  },
  {
    label: 'ログアウト',
    icon: <LogoutIcon />,
    href: '/account/sign-in',
    onClick: (e) => {
      e.preventDefault()
      document.cookie = ''
      setCookie(null, 'token', '', {
        maxAge: 0,
        path: '/',
      })
      router.push('/account/sign-in')
    },
    show: true,
  },
]
