import { initGet1000Data } from '@/model/master/phoneLinePartnersModels'
import { INIT_PAGE, PER_PAGE_ALL } from '@/src/const/settings'

import { client } from '../config/clientApi'
import { CALL_CENTER_STAFF } from './userRole'

export type QueryResourceType = {
  key: string
  fetcher: (data: any, data2?: any) => Promise<any> | any
}

export const snackbarResource: QueryResourceType = {
  key: 'Snackbar',
  fetcher: () => {},
}

export const userIdResource: QueryResourceType = {
  key: client.api().user.$path(),
  fetcher: () => client.api().user.$get(),
}

export const callCenterUsersIdResource: QueryResourceType = {
  key: client.api().users.$path({
    query: { page: INIT_PAGE, per_page: PER_PAGE_ALL, user_role_id: CALL_CENTER_STAFF },
  }),
  fetcher: () =>
    client.api().users.$get({
      query: { page: INIT_PAGE, per_page: PER_PAGE_ALL, user_role_id: CALL_CENTER_STAFF },
    }),
}

export const partnerListResource: QueryResourceType = {
  key: client.api().master.partners.$path(),
  fetcher: () =>
    client.api().master.partners.$get({
      query: {
        page: INIT_PAGE,
        per_page: PER_PAGE_ALL,
      },
    }),
}

export const PhoneLineResource: QueryResourceType = {
  key: client.api().master.phone_lines.$path(),
  fetcher: (partner_id: number) =>
    client.api().master.phone_lines.$get({
      query: {
        partner_id: Number(partner_id),
        page: INIT_PAGE,
        per_page: PER_PAGE_ALL,
      },
    }),
}

export const PhoneLineStoreResource: QueryResourceType = {
  key: 'PhoneLineStore',
  fetcher: (phone_line_id: number) =>
    client.api().master.phone_lines._phone_line_id(phone_line_id).stores.$get(),
}

export const PhoneLineHallResource: QueryResourceType = {
  key: 'PhoneLineHalls',
  fetcher: (id: number) => client.api().master.phone_lines._phone_line_id(id).halls.$get(),
}

export const PhoneLineJAResource: QueryResourceType = {
  key: 'PhoneLineJA',
  fetcher: (id: number) =>
    client.api().master.phone_lines._phone_line_id(id).japan_agriculturals.$get(),
}

export const PhoneLineJABranchResource: QueryResourceType = {
  key: 'PhoneLineJABranch',
  fetcher: (id: Array<number>) => {
    const [phoneLineId, PhoneLineJA] = id
    return client
      .api()
      .master.phone_lines._phone_line_id(phoneLineId)
      .japan_agriculturals._japan_agricultural_id(PhoneLineJA)
      .japan_agricultural_branches.$get()
  },
}

export const japanAgriculturalGroupsListResource: QueryResourceType = {
  key: 'client.api().master.japan_agricultural_groups.$path()',
  fetcher: () =>
    client.api().master.phone_lines.$get({
      query: {
        page: INIT_PAGE,
        per_page: PER_PAGE_ALL,
      },
    }),
}

export const PhoneLinePartnerListResourceForPhoneLine = (
  phoneLineId: number
): QueryResourceType => {
  return {
    key: client.api().master.phone_lines._phone_line_id(phoneLineId).phone_line_partners.$path(),
    fetcher: () =>
      client
        .api()
        .master.phone_lines._phone_line_id(phoneLineId)
        .phone_line_partners.$get({
          query: {
            ...initGet1000Data,
          },
        }),
  }
}

export const japanAgriculturalListResource: QueryResourceType = {
  key: client.api().master.japan_agriculturals.$path(),
  fetcher: () =>
    client.api().master.japan_agriculturals.$get({
      query: {
        page: INIT_PAGE,
        per_page: PER_PAGE_ALL,
      },
    }),
}

// export const regionResource: QueryResourceType = {
//   key: client.api().master.regions.$path(),
//   fetcher: () =>
//     client.api().master.regions.$get({
//       query: {
//         page: INIT_PAGE,
//         per_page: PER_PAGE_ALL,
//       },
//     }),
// }

//葬祭センター一覧（業務画面用）
export const PhoneLineBusinessResource: QueryResourceType = {
  key: 'PhoneLineBusiness',
  fetcher: (partner_id: number) => {
    return client.api().master.partners._partner_id(partner_id).phone_lines.$get()
  },
}

export const userRolesResource: QueryResourceType = {
  key: 'userRoles',
  fetcher: () => {
    return client.api().user_roles.$get()
  },
}
