import { MutationCache, QueryCache, QueryClient } from 'react-query'

export const ERROR_HANDLE_KEY = 'errorHandle'

const queryClientConfig = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onSuccess: () => {
      queryClientConfig.setQueryData(ERROR_HANDLE_KEY, '')
    },
    onError: (error: any, query) => {
      if (error?.response?.data?.message === 'この業務情報の閲覧権限がありません。') {
        queryClientConfig.setQueryData('Snackbar', {
          message: 'この業務情報の閲覧権限がありません。',
          severity: 'error',
        })
        return
      }
      queryClientConfig.setQueryData(ERROR_HANDLE_KEY, error)
    },
  }),
  mutationCache: new MutationCache({
    onSuccess: () => {
      queryClientConfig.setQueryData(ERROR_HANDLE_KEY, '')
    },
    onError: (error: any) => {
      if (error?.response?.data?.message === 'この業務情報の閲覧権限がありません。') {
        queryClientConfig.setQueryData('Snackbar', {
          message: 'この業務情報の閲覧権限がありません。',
          severity: 'error',
        })
        return
      }
      queryClientConfig.setQueryData(ERROR_HANDLE_KEY, error)
    },
  }),
})

export default queryClientConfig
