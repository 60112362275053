import { useCallback, useEffect, useState } from 'react'
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query'

export const useSelectId = <T extends any>(
  key: string,
  id?: string | number | Array<any>,
  option?:
    | Omit<UseQueryOptions<unknown, unknown, unknown, string>, 'queryKey' | 'queryFn'> & {
        tryRefetch?: boolean
      }
) => {
  const queryClient = useQueryClient()
  const [refetch, setRefetch] = useState(false)
  const { data } = useQuery(
    key,
    () => queryClient.getQueryData<T | string | number | null | undefined>(key),
    {
      enabled: !!refetch || !option?.tryRefetch,
      cacheTime: 0,
      staleTime: Infinity,
      retryOnMount: true,
      ...option,
    }
  )
  useEffect(() => {
    if (id && (!refetch || !option?.tryRefetch)) {
      queryClient.setQueryData(key, id)
      setRefetch(true)
    }
  }, [id, key, option?.tryRefetch, queryClient, refetch])

  const setQuery = useCallback(
    (value?: T | string | number | null | undefined) => {
      setRefetch(true)
      queryClient.setQueryData(key, value)
    },
    [key, queryClient]
  )
  return {
    id: data as T,
    setQuery,
  }
}
