import { Alert, css } from '@mui/material'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { ERROR_HANDLE_KEY } from '@/src/config/reactQueryConfig'
import { convertErrorMessageToUserReadables } from '@/src/utils/convertErrorMessage'

type Props = {
  children: React.ReactNode
  isPageName?: string
}

const ErrorHandler: React.FC<Props> = ({ children, isPageName }) => {
  const ref = React.createRef<HTMLDivElement>()
  const client = useQueryClient()
  const queryClient = useQueryClient()
  const [visible, setVisible] = useState(false)
  const [scrollMove, setScrollMove] = useState(false)
  const router = useRouter()
  const { data } = useQuery(
    ERROR_HANDLE_KEY,
    () =>
      client.getQueryData<
        AxiosError<{ message: string; errors?: { [name: string]: Array<string> } }>
      >(ERROR_HANDLE_KEY),
    {
      staleTime: 2000,
      cacheTime: Infinity,
    }
  )

  const [error, setError] = useState<string | Array<string>>('')
  const [changes, setChanges] = useState(0)

  useEffect(() => {
    setChanges((prev) => prev + 1)
    setVisible(false)
    setError('')
  }, [router.asPath])

  useEffect(() => {
    setVisible(true)
  }, [data])

  useEffect(() => {
    if (error && !scrollMove) {
      setScrollMove(true)
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [data, error, ref, scrollMove])

  useEffect(() => {
    if (!error) {
      setScrollMove(false)
      setVisible(false)
    }
  }, [error])

  useEffect(() => {
    if (isPageName === '/_error') {
      router.push('/404')
      return
    }
    if (data) {
      if (error) return
      if (data.response?.data?.message.startsWith('No query results for model')) {
        setError('エラーが発生しました。')
        return
      }

      if (data.status === '401') {
        if (router.pathname !== '/account/sign-in') {
          router.push('/account/sign-in')
        }
        return
      }
      if (router.route === '/master/phone_lines/[id]/edit') {
        setError((data as any)?.response.data.message)
        return
      }
      if (data.response?.data?.errors) {
        setError(convertErrorMessageToUserReadables(data.response.data.errors))
        return
      }

      if (typeof data.response?.data?.message === 'string') {
        if (data.response.data.message.startsWith('SQLSTATE[23000]')) {
          setError('このスタッフに紐づく葬祭スタッフがあるため削除できません。')
          return
        }
        if (data.response.data.message.startsWith('Unauthenticated')) {
          setError('ログインできません。')
          return
        }
        if (data.response.data.message.startsWith('削除できません')) {
          setError(data?.response?.data.message)
          return
        }
        setError(data?.response?.data.message)
        return
      }

      if (data?.message) {
        setError(data.message)
        return
      }

      setError('エラーが発生しました。')
      return
    }
  }, [data, error, isPageName, router, setError, visible])

  if (isPageName === '/_error') return null

  return (
    <>
      {error &&
        visible &&
        (typeof error == 'string' ? (
          <Alert
            ref={ref}
            css={css`
              width: 100%;
            `}
            severity="error"
            onClose={() => {
              queryClient.setQueryData(ERROR_HANDLE_KEY, '')
              setError('')
              setVisible(false)
            }}
          >
            {error}
          </Alert>
        ) : (
          (error as Array<string>)?.map((e, i) => (
            <Alert
              key={i}
              ref={ref}
              css={css`
                width: 100%;
              `}
              severity="error"
            >
              {e}
            </Alert>
          ))
        ))}
      {children}
    </>
  )
}

export default ErrorHandler
