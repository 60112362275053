import { pagesPath } from '@/generated/lib/$path'

export const unAuthorizedPages: Array<string> = [
  pagesPath.account.sign_in.$url().pathname,
  pagesPath.$404.$url().pathname,
]

export const disabledLayoutPage: Array<string> = [
  pagesPath.my.phoneLine.attentions.$url().pathname,
  ...unAuthorizedPages,
]

export const authorizedPagesMasterExceptStaff: Array<string> = [
  pagesPath.master.phone_lines.$url().pathname,
  pagesPath.master.centers.$url().pathname,
  pagesPath.master.halls.$url().pathname,
  pagesPath.master.areas.$url().pathname,
  pagesPath.master.regions.$url().pathname,
  pagesPath.master.ja_branches.$url().pathname,
  pagesPath.master.jas.$url().pathname,
  pagesPath.master.partners.$url().pathname,
  pagesPath.master.stores.$url().pathname,
  pagesPath.master.summary.$url().pathname,
]

//pagesPathから自動でFetch機能が必要
export const allPathnames: { [key: string]: any } = {
  '/404': '/404',
  '/account/sign-in': '/account/sign-in',
  '/call-center/handovers/[handoverId]/detail': '/call-center/handovers/[handoverId]/detail',
  '/call-center/handovers/[handoverId]/edit': '/call-center/handovers/[handoverId]/edit',
  '/call-center/handovers': '/call-center/handovers',
  '/master/areas/[areaId]/detail': '/master/areas/[areaId]/detail',
  '/master/areas/[areaId]/edit': '/master/areas/[areaId]/edit',
  '/master/areas/[areaId]/subArea/[subAreaId]/detail':
    '/master/areas/[areaId]/subArea/[subAreaId]/detail',
  '/master/areas/[areaId]/subArea/[subAreaId]/edit':
    '/master/areas/[areaId]/subArea/[subAreaId]/edit',
  '/master/areas': '/master/areas',
  '/master/centers/[id]/detail': '/master/centers/[id]/detail',
  '/master/centers/[id]/edit': '/master/centers/[id]/edit',
  '/master/centers/[id]/hospitals/[hospitalId]/detail':
    '/master/centers/[id]/hospitals/[hospitalId]/detail',
  '/master/centers/[id]/hospitals/[hospitalId]/edit':
    '/master/centers/[id]/hospitals/[hospitalId]/edit',
  '/master/centers/[id]/mortuaries/[mortuaryId]/detail':
    '/master/centers/[id]/mortuaries/[mortuaryId]/detail',
  '/master/centers/[id]/mortuaries/[mortuaryId]/edit':
    '/master/centers/[id]/mortuaries/[mortuaryId]/edit',
  '/master/centers': '/master/centers',
  '/master/halls/[id]/detail': '/master/halls/[id]/detail',
  '/master/halls/[id]/edit': '/master/halls/[id]/edit',
  '/master/halls': '/master/halls',
  '/master/ja-branches/[id]/detail': '/master/ja-branches/[id]/detail',
  '/master/ja-branches/[id]/edit': '/master/ja-branches/[id]/edit',
  '/master/ja-branches': '/master/ja-branches',
  '/master/jas/[id]/detail': '/master/jas/[id]/detail',
  '/master/jas/[id]/edit': '/master/jas/[id]/edit',
  '/master/jas': '/master/jas',
  '/master/partners/[id]/detail': '/master/partners/[id]/detail',
  '/master/partners/[id]/edit': '/master/partners/[id]/edit',
  '/master/partners': '/master/partners',
  '/master/phone_lines/[id]/areas/[areaId]/edit': '/master/phone_lines/[id]/areas/[areaId]/edit',
  '/master/phone_lines/[id]/attentions/[attentionId]/edit':
    '/master/phone_lines/[id]/attentions/[attentionId]/edit',
  '/master/phone_lines/[id]/call-system-memos/[callSystemMemoId]/edit':
    '/master/phone_lines/[id]/call-system-memos/[callSystemMemoId]/edit',
  '/master/phone_lines/[id]/carrier-conditions/[carrierConditionId]/edit':
    '/master/phone_lines/[id]/carrier-conditions/[carrierConditionId]/edit',
  '/master/phone_lines/[id]/carrier-conditions': '/master/phone_lines/[id]/carrier-conditions',
  '/master/phone_lines/[id]/carriers/[carrierId]/edit':
    '/master/phone_lines/[id]/carriers/[carrierId]/edit',
  '/master/phone_lines/[id]/detail': '/master/phone_lines/[id]/detail',
  '/master/phone_lines/[id]/edit': '/master/phone_lines/[id]/edit',
  '/master/phone_lines/[id]/manuals': '/master/phone_lines/[id]/manuals',
  '/master/phone_lines/[id]/phone_line_partners/[phoneLinePartnersId]/edit':
    '/master/phone_lines/[id]/phone_line_partners/[phoneLinePartnersId]/edit',
  '/master/phone_lines/[id]/report-destinations/[reportDestinationId]/edit':
    '/master/phone_lines/[id]/report-destinations/[reportDestinationId]/edit',
  '/master/phone_lines': '/master/phone_lines',
  '/master/regions/[id]/detail': '/master/regions/[id]/detail',
  '/master/regions/[id]/edit': '/master/regions/[id]/edit',
  '/master/regions': '/master/regions',
  '/master/stores/[id]/detail': '/master/stores/[id]/detail',
  '/master/stores/[id]/edit': '/master/stores/[id]/edit',
  '/master/stores': '/master/stores',
  '/master/summary': '/master/summary',
  '/master/users/[id]/detail': '/master/users/[id]/detail',
  '/master/users/[id]/edit': '/master/users/[id]/edit',
  '/master/users': '/master/users',
  '/my/funerals/[funeralId]/confirm': '/my/funerals/[funeralId]/confirm',
  '/my/funerals/[funeralId]/detail': '/my/funerals/[funeralId]/detail',
  '/my/funerals/[funeralId]/edit': '/my/funerals/[funeralId]/edit',
  '/my/funerals': '/my/funerals',
  '/my/phoneLine/attentions': '/my/phoneLine/attentions',
  '/my/tasks/contacts/[taskId]/confirm': '/my/tasks/contacts/[taskId]/confirm',
  '/my/tasks/contacts/[taskId]/detail': '/my/tasks/contacts/[taskId]/detail',
  '/my/tasks/contacts/[taskId]/edit': '/my/tasks/contacts/[taskId]/edit',
  '/my/tasks/new': '/my/tasks/new',
  '/my/tasks/orders/[taskId]/confirm': '/my/tasks/orders/[taskId]/confirm',
  '/my/tasks/orders/[taskId]/detail': '/my/tasks/orders/[taskId]/detail',
  '/my/tasks/orders/[taskId]/edit': '/my/tasks/orders/[taskId]/edit',
  '/my/tasks/pending-list': '/my/tasks/pending-list',
  '/my/tasks/receptions/[taskId]/confirm': '/my/tasks/receptions/[taskId]/confirm',
  '/my/tasks/receptions/[taskId]/detail': '/my/tasks/receptions/[taskId]/detail',
  '/my/tasks/receptions/[taskId]/edit': '/my/tasks/receptions/[taskId]/edit',
  '/my/tasks/transports/[taskId]/confirm': '/my/tasks/transports/[taskId]/confirm',
  '/my/tasks/transports/[taskId]/detail': '/my/tasks/transports/[taskId]/detail',
  '/my/tasks/transports/[taskId]/edit': '/my/tasks/transports/[taskId]/edit',
  '/my/tasks': '/my/tasks',
  '/': '/',
}
