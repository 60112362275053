export const TEL_PATTERN = {
  value: /^0\d{1,4}-\d{1,4}-\d{3,4}$|^0[0-9]{9,12}$/,
  message: '正しい形式で入力してください。',
}

export const NUMBER_PATTERN = {
  required: '入力してください。',
  valueAsNumber: true,
  message: '数字を入力してください。',
}

export const KANA_PATTERN = {
  value: /^[\u3040-\u309F\u30A0-\u30FF\uFF61-\uFF9F 　]+$/i, //ひらがな カタカナ 半角カタカナ 半角スペース 全角スペース
  message: 'ひらがな・カタカナを入力してください。',
}

const orderValidationMessage = '1~999までの値を入力してください。 '

export const EMAIL_PATTERN = {
  required: '入力してください。',
  value: /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+.{0,1})+[^.]@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  message: '正しい形式で入力してください。',
}

// export const EMAIL_REGEX =
//   /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+.{0,1})+[^.]@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
// export const TEL_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/
// export const URL_REGEX = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
// export const YOUTUBE_URL_REGEX = /^https:\/\/youtu\.be\//u
// export const POSTAL_CODE_REGEX = /^[0-9]{7}$/
// export const ACCOUNT_NUMBER_REGEX = /^\d{7}$/
// export const DATE_REGEX = /^(\d+)-([0-1]\d{1})-([0-3]\d{1})$/u
// export const MONTH_REGEX = /^([1-9]|1[0-2])$/
// export const TIME_REGEX = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/
// export const NOTICE_DATE_REGEX = /^(\d{4}).([0-1]\d{1}).([0-3]\d{1})$/
// export const FUNERAL_CASE_ID_REGEX = /^[a-zA-Z]+-[0-9]{4}$/
// export const ONLY_NUMBER_REGEX = /^[0-9]+$/
// export const LOTTERY_NUMBER_REGEX = /^[shj]{1}1219[0-9]{3}$/
// // http://www.regular-expressions.info/creditcard.html
// export const CREDIT_CARD_NUMBER_REGEX =
//   /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
// export const CREDIT_CARD_SECURITY_CODE = /^[0-9]{3,4}$/
// export const CREDIT_CARD_ACCOUNT_NAME = /(^[a-z0-9]+\s?[a-z0-9]+$)/i
