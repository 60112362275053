import { TableHeaderType } from '@/components/common/MwTable'
import { PhoneLinePartner } from '@/generated/api/@types'
import { FormFieldsType } from '@/src/const/form/formTypes'
import { NUMBER_PATTERN } from '@/src/const/form/validatePatterns'
import { INIT_PAGE, PER_PAGE_ALL } from '@/src/const/settings'

export const pageName = '提携業者'

export const initGet1000Data = {
  page: INIT_PAGE,
  per_page: PER_PAGE_ALL,
  is_disabled: 0, //サーバー側で対応できない、実際Booleanだけど、サーバー側は０に出さなきゃいけない
} as any

export const editDefaultValue = (data?: PhoneLinePartner) => ({
  name: data?.name,
  priority: data?.priority,
  note: data?.note,
  is_disabled: !!data?.is_disabled,
  cautions_for_carry: data?.cautions_for_carry,
})

export const editFormFields: Array<FormFieldsType> = [
  {
    name: 'name',
    label: '提携業者名',
    registerOption: { required: '入力してください。' },
  },
  {
    name: 'priority',
    type: 'number',
    label: '優先度',
    registerOption: { ...NUMBER_PATTERN },
  },
  {
    name: 'cautions_for_carry',
    label: '搬送時の注意事項',
    rows: 3,
  },
  {
    name: 'note',
    label: '備考',
    rows: 3,
  },
  {
    name: 'is_disabled',
    type: 'checkbox',
    label: '使用不可',
  },
]

export const masterPhoneLinePartnerHeader: TableHeaderType[] = [
  { id: 'id', label: 'id', type: 'hide' },
  { id: 'name', label: '提携業者名' },
  { id: 'priority', label: '優先度' },
  { id: 'cautions_for_carry', label: '搬送時の注意事項' },
  { id: 'note', label: '備考' },
  { id: 'is_disabled', label: '使用不可', type: 'checkbox' },
  { id: '', label: '', type: 'button' },
]
