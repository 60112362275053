import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

import MwFooter from '@/components/common/MwFooter'
import { client, cookieExpires } from '@/src/config/clientApi'
import { userIdResource } from '@/src/const/resource'

const defaultValues = {
  login_name: '',
  password: '',
}

const loginApi = (body: { login_name: string; password: string }) => {
  return client.api().login.$post({ body })
}

const userApi = () => {
  return client.api().user.$get()
}

const SignIn: React.FC = () => {
  const [_, setCookie, removeCookie] = useCookies(['token'])
  const router = useRouter()
  const clientQuery = useQueryClient()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
  })

  const [isLoginError, setIsLoginError] = React.useState(false)

  useEffect(() => {
    clientQuery.clear()
  }, [clientQuery, removeCookie])

  useEffect(() => {
    removeCookie('token', { path: '/' })
  }, [removeCookie])

  const { mutate: userInfo } = useMutation(userApi, {
    onSuccess: (data) => {
      clientQuery.setQueryData(userIdResource.key, data)
      if (router.query?.taskInfo) {
        router.push(`/my/tasks/${router.query?.taskInfo}`)
        return
      }
      router.push(`/`)
    },
  })
  const { data: token, mutate } = useMutation(loginApi, {
    onSuccess: (data) => {
      client.setToken(data.token)
      userInfo()
      setCookie('token', data.token, cookieExpires)
    },
    onError: () => {
      setIsLoginError(true)
    },
  })

  const onSubmit = React.useCallback(
    (data: typeof defaultValues) => {
      setIsLoginError(false)
      mutate({ ...data })
    },
    [mutate]
  )

  return (
    <>
      <Container maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" mt={1}>
            葬祭業務支援システム
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={1} noValidate>
            <Controller
              name="login_name"
              control={control}
              rules={{ required: 'ユーザーIDを入力してください' }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  margin="normal"
                  type="text"
                  label="ユーザーID"
                  fullWidth
                  error={!!errors.login_name}
                  helperText={fieldState.error?.message}
                  autoFocus
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: 'パスワードを入力してください' }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="password"
                  label="パスワード"
                  fullWidth
                  error={!!errors?.password}
                  helperText={fieldState.error?.message}
                  autoFocus
                />
              )}
            />
            {isLoginError && (
              <Typography mt={2} color="red" textAlign="center">
                ユーザーIDまたはパスワードに誤りがあります
              </Typography>
            )}
            <Button
              color="primary"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
            >
              ログイン
            </Button>
            <Grid container justifyContent="center" alignContent="center">
              <Grid item xs display={'contents'}>
                パスワードを忘れた場合は管理者へ連絡してください
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <MwFooter />
    </>
  )
}

export default SignIn
